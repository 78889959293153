<template>
  <div>
    <dashboard-top-menu
      :isCompanyDashboard="true"
      @set-selected-dashboard="setSelectedDashboard"
      @open-add-widget="showAddWigetPopup=true"
    />

    <dashboard-widget-cards 
      v-if="activeDashboard"
      :widgetList="widgetList"
      :dashboardId="activeDashboard.id"
      :isCompanyDashboard="true"
      :isDashboardReadonly="isDashboardReadonly"
    />

  </div>
</template>

<script>
import DashboardWidgetPopup from './DashboardWidgetPopup.vue'
import companyDashboardStore from './companyDashboardStore.js'
import DashboardTopMenu from './components/DashboardTopMenu.vue'
import DashboardWidgetCards from './components/DashboardWidgetCards.vue'

export default {
  components: {
    DashboardWidgetPopup,
    DashboardTopMenu,
    DashboardWidgetCards
  },
  props: [
    'companyId',
    'dashboardId'
  ],
  computed: {
    activeDashboard () {
      return this.$store.state.companyDashboardStore.activeDashboard
    },
    widgetList () {
      return this.$_.sortBy(this.$store.state.companyDashboardStore.widgets, ['orderId'])
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    isDashboardReadonly () {
      return this.$store.state.companyDashboardStore.activeDashboard 
        && this.$store.state.companyDashboardStore.activeDashboard.companyId === 0
    }
  },
  created () {    
    if (!companyDashboardStore.isRegistered) {
      this.$store.registerModule('companyDashboardStore', companyDashboardStore)
      companyDashboardStore.isRegistered = true
    }

    //Check if user has acccess to the company
    if(this.activeUserInfo.companyId != this.$route.params.companyId) {
      this.$router.push('/error-404')
    }


    this.$vs.loading()
    this.$store.dispatch('companyDashboardStore/fetchDashboards', { companyId: this.$route.params.companyId })
    .then(response => {
      if (this.dashboardId) {
        const val = this.$_.find(this.$store.state.companyDashboardStore.dashboards, x =>
          x.id == this.dashboardId);
        this.$store.dispatch('companyDashboardStore/persistActiveDashboard', val)
      }
      else if (!this.$store.state.companyDashboardStore.activeDashboard) {
        this.$store.dispatch('companyDashboardStore/setActiveDashboardToDefault')
      }
      this.fetchWidgets()
        .finally(() => this.$vs.loading.close())
    })
    .catch(error => console.error(error))
    
  },
  methods: {
    fetchWidgets() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`companyDashboardStore/fetchWidgets`, { 
          companyId: this.companyId,
          dashboardId: this.activeDashboard.id
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },

    setSelectedDashboard (value) {
      const val = this.$_.find(this.$store.state.companyDashboardStore.dashboards, x =>
        x.id === value.code);
      this.$store.dispatch('companyDashboardStore/persistActiveDashboard', val)

      this.$vs.loading()
      this.fetchWidgets()
        .finally(() => this.$vs.loading.close())  
    }
    
  }
}
</script>